import { IItemOfEvents } from '@ucheba/store/events/types'
import { IPropHref, IPropOnClick } from '@ucheba/utils/helpers/components/prop.types'
import { IModColor, IModProgress } from '@ucheba/utils/helpers/styles/mod.types'
import { INotice } from '@ucheba/ui/components/Notice/types'
import { IEventsId } from '@ucheba/store/events/id/types'
import { IImage } from '@ucheba/store/institutions/item/types'

export interface IEventsListProps {
  isBanner?: boolean

  /** Необходимо ли запрашивать разрешение писать в ВК */
  needVk?: boolean

  /** Ссылка для нотиса регистраици через ВК */
  linkReg?: string

  /** параметры для баннеров */
  params?: {
    [key: string]: any
  }

  /** Ccылка для перехода в карточку мероприятия */
  href: string

  /** Список id мероприятий, экшены которых находятся в статусе отправки */
  actionsPending?: number[]

  /* Список специальностей */
  items: IEventsListItemProps[]

  /** Клик на кнопку участия */
  onClickAction?: (id: IEventsListItemProps['id']) => void
}

export interface IEventsListItemProps
  extends IItemOfEvents,
    Pick<IEventsListProps, 'onClickAction'> {
  /** Если экшен мероприятия находится в статусе отправки */
  isActionPending?: boolean
  isDivider?: boolean

  /** Сссылка на карточку мероприятия */
  pathname: string

  /** Необходимо ли запрашивать разрешение писать в ВК */
  needVk?: boolean

  /** Показать разделитель завершенного мероприятия */
  isFinishedLine?: boolean

  /** Ссылка для нотиса регистраици через ВК */
  linkReg?: string

  isAllowedMess?: boolean
}

export interface IUseEventsListItemCore {
  (props: IUseEventsListItemCoreProps): {
    onClickRegistration: () => void
    description: string | null
    isEventStartedOrHour: boolean
    buttonData: {
      eventRegisteredStatus: EEventRegisteredStatus
      buttonText: string
    }
    notices: {
      onlineEventStartedNotice: INotice
      offlineEventNotice: INotice
      onlineEventNotStartedNotice: INotice
      notAllowedMessegerNotice: INotice
    }
    institution: null | {
      name: string
      location: {
        id: number | string
        name: string
      }
      href: string
      logo: IImage
    }
    onlineEventStartedNotice: INotice
    registersPending: boolean
    offlineEventNotice: INotice
    href: string
    onlineEventNotStartedNotice: INotice
    directVkStreamUrl: string | null | undefined
  }
}

interface IUseEventsListItemCoreProps extends IEventsId {
  pathname?: string
  isAllowedMess?: boolean
}

export enum EEventRegisteredStatus {
  isRegistered = 'isRegistered',
  isNotRegistered = 'isNotRegistered',
  watchBroadcast = 'watchBroadcast',
}

export interface IEventsListItemButtonProps
  extends IPropOnClick,
    IModColor,
    IPropHref,
    IModProgress {
  registeredStatus: keyof typeof EEventRegisteredStatus
}

export enum EEventFormat {
  online = 'online',
  offline = 'offline',
}

export interface IUseFinishedEvents {
  (events: IItemOfEvents[]): (index: number) => boolean
}
